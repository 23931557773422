import React from "react"
import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid"
import Container from "@material-ui/core/Container"
import Typography from "@material-ui/core/Typography"

import { graphql, Link } from "gatsby"
import { makeStyles } from "@material-ui/core/styles"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import Title from "../../components/title"
import Hero from "../../components/hero"
import Layout from "../../components/layout"
import Button from "../../components/button"
import TeaserBox from '../../components/teaserBox'

// import Seo from "../../components/seo"
// import Timeline from "../../components/timeline"

const useStyles = makeStyles(theme => ({

	pageWrapper: {
		paddingBottom: '50px',
		overflow: 'hidden',

		'& h2': {
			'@media (max-width: 480px)': {
				margin: '20px 0',
				fontSize: '25px',
			},
		},

		'& p': {
			'@media (max-width: 480px)': {
				fontSize: '16px',
			},
		},
	},

	grid: {
		marginTop: theme.spacing(10),
		marginBottom: theme.spacing(10),
	},

	fullScreenWrapper: {
		overflow: 'hidden',
		backgroundColor: '#fff',
		width: '100vw',
		padding: '0 150px',
		marginLeft: 'calc(-50vw + 50% - 0px)',

		'@media (max-width: 959px)': {
			width: '100%',
			margin: 0,
			padding: 0,
			backgroundColor: 'transparent',
		},

		'& div> div:nth-child(1)': {
			backgroundColor: '#fff',
			[theme.breakpoints.down('md')]: {
				padding: '15px 25px 0',
			},
		},

		'& div> div:nth-child(2)': {
			backgroundColor: 'transparent',
		},
	},

	itemImage: {
		'@media (max-width: 959px)': {
			padding: 0,

			'div': {
				width: "100%",
			}
		},
	},

	heroTitle: {
		'& section > h1': {
			[theme.breakpoints.down('lg')]: {
				fontSize: '100px',
			},
			'@media(max-width: 687px)': {
				fontSize: '70px ',
			},
			[theme.breakpoints.down('xs')]: {
				fontSize: '50px ',
			},
		},

	},

	itemTitleWithRedBackground: {
		margin: '0 0 20px 80px',

		[theme.breakpoints.down('sm')]: {
			margin: '30px 0 20px 40px',
		},
	},

	itemWithRedBackground: {
		position: 'relative',
		padding: '80px',
		color: theme.palette.common.white,

		[theme.breakpoints.down('sm')]: {
			padding: '40px',
		},

		'&:after': {
			content: "''",
			width: 'calc(100% + 150px)',
			height: 'calc(100% - 20px)',
			top: '20px',
			left: '-150px',
			position: 'absolute',
			display: 'inline-block',
			backgroundColor: '#A71C25',
			zIndex: -1,

			[theme.breakpoints.down('md')]: {
				width: '100%',
				top: 0,
				left: 0,
			},
		},
	},

	itemWithWhiteBackground: {
		position: 'relative',
		padding: theme.spacing(12),

		[theme.breakpoints.down('md')]: {
			padding: theme.spacing(6),
		},
		[theme.breakpoints.down('sm')]: {
			padding: theme.spacing(2),
		},

		'&:before': {
			content: "''",
			width: 'calc(100% + 150px)',
			height: 'calc(100% - 20px)',
			top: 0,
			left: 0,
			position: 'absolute',
			display: 'inline-block',
			backgroundColor: theme.palette.common.white,
			zIndex: -1,

			[theme.breakpoints.down('md')]: {
				width: '100%',
				top: 0,
				left: 0,
			},
		},

		'&:after': {
			content: "''",
			width: '50px',
			height: '150px',
			top: 0,
			left: '-50px',
			position: 'absolute',
			display: 'inline-block',
			backgroundColor: '#A71C25',
			zIndex: -2,

			[theme.breakpoints.down('md')]: {
				width: '20px',
				height: '50px',
				top: 0,
			},
		},
	},

	moreThanNumTitle: {
		'& h2:after': {
			width: '15%',
		},
	},

	gridSaddlefitters: {
		justifyContent: 'center'
	},

	itemSaddlefitters: {
		position: 'relative',
		overflow: 'hidden',
		padding: '0 !important',
		margin: '12px',
		display: 'flex',

		[theme.breakpoints.up('md')]: {
			margin: 0,
			padding: 'initial !important',

			'&:hover > span': {
				opacity: 1,
				padding: '0 30px 250px',

				'& span div': {
					bottom: '20px',
				}
			}
		}
	},

	itemSaddlefittersTextMobile: {
		display: 'none',
		justifyContent: 'center',
		alignItems: "center",
		flexDirection: 'column',
		position: 'absolute',
		bottom: '0',
		left: '50%',
		transform: 'translateX(-50%)',
		width: "100%",
		padding: '12%',
		color: theme.palette.common.white,
		backgroundColor: 'rgba(0,0,0,0.7)',

		'& h2': {
			color: theme.palette.common.white
		},

		'@media (max-width: 1080px)': {
			display: 'initial',
			'& h2': {
				fontSize: theme.spacing(2.5),

				'&:after': {
					height: '2px'
				}
			},
		},
	},

	itemSaddlefittersTextDesktopWrapper: {
		opacity: 0,
		position: 'absolute',
		top: 0,
		left: 0,
		width: '85%',
		height: '100%',
		backgroundColor: 'rgba(0,0,0,0.7)',
		transition: '0.3s',

		'@media (min-width: 1080px)': {
			color: '#fff',
			display: 'flex',
			alignItems: 'flex-end',
			padding: '0 30px 350px',

			'& h2': {
				color: '#fff',
				fontSize: '50px',

				'&:after': {
					height: '2px'
				}
			},
		},

	},


}))

const SaddlefittingPage = ({ data }) => {
	const classes = useStyles()
	const {
		heroTitle,
		heroImage,
		pageSaddlefittingTitle,
		pageSaddlefittingDescription,
		pageSaddlefittingImage,
		moreThanNumbersTitle,
		moreThanNumbersDescription,
		correctionTitle,
		correctionDescription,
		correctionImage,
		consultationTitle,
		consultationDescription,
		consultationButton,
		consultationImage,
		ourSaddlefittersTitle,
		ourSaddlefittersButton,
		ourSaddlefittersImages,
	} = (data && data.datoCmsPageSaddlefitting) || {}


	return (
		<Layout >
			<Box className={classes.pageWrapper}>
				<Box className={classes.heroTitle}><Hero full title={heroTitle} image={heroImage} /></Box>
				<Container maxWidth="lg">
					<Box className={classes.fullScreenWrapper}>
						<Grid container spacing={3} className={classes.grid}>
							<Grid item md={6} sm={12} className={classes.item}>
								<Title >{pageSaddlefittingTitle}</Title>
								<Typography component="div" dangerouslySetInnerHTML={{ __html: pageSaddlefittingDescription }} />
							</Grid>
							<Grid item md={6} sm={12} className={classes.itemImage}>
								<GatsbyImage image={getImage(pageSaddlefittingImage)} alt={pageSaddlefittingTitle} />
							</Grid>
						</Grid>
					</Box>
					<Box my={4}>
						<Box mb={3} display="flex" justifyContent="center" className={classes.moreThanNumTitle}>
							<TeaserBox teaserTitle={moreThanNumbersTitle} teaserDescription={moreThanNumbersDescription} />
						</Box>
					</Box>
					<Grid container spacing={3} className={classes.grid}>
						<Grid item md={6} sm={12} className={classes.item}>
							<GatsbyImage image={getImage(consultationImage)} alt={consultationTitle} />
						</Grid>
						<Grid item md={6} sm={12} className={classes.item}>
							<Title className={classes.itemTitleWithRedBackground}>{consultationTitle}</Title>
							<div className={classes.itemWithRedBackground}><Typography component="div" dangerouslySetInnerHTML={{ __html: consultationDescription }} />
								<Box display="flex" justifyContent="center" mt={6}>
									<Link to="/kontakt">
										<Button bgColor="#000" size="medium">{consultationButton}</Button>
									</Link>
								</Box>
							</div>
						</Grid>
					</Grid>
					<Grid container spacing={3} className={classes.grid}>
						<Grid item md={6} sm={12} className={classes.item}>
							<Title>{correctionTitle}</Title>
							<Typography component="div" dangerouslySetInnerHTML={{ __html: correctionDescription }} className={classes.itemWithWhiteBackground} />
						</Grid>
						<Grid item md={6} sm={12} className={classes.item}>
							<GatsbyImage image={getImage(correctionImage)} alt={correctionTitle} />
						</Grid>
					</Grid>
					<Grid container spacing={3} className={classes.gridSaddlefitters}>
						<Grid item xs={12} className={classes.item}>
							<Title>{ourSaddlefittersTitle}</Title>
						</Grid>

						{ourSaddlefittersImages.map(({ id, image, title, description }) => (
							<Grid item sm={6} xs={12} className={classes.itemSaddlefitters} key={id}>
								<GatsbyImage image={getImage(image)} alt={correctionTitle} />
								<div className={classes.itemSaddlefittersTextMobile}>
									<Title>{title}</Title>
									<Typography component="div" dangerouslySetInnerHTML={{ __html: description }} /></div>
								<span className={classes.itemSaddlefittersTextDesktopWrapper}>
									<div className={classes.itemSaddlefittersTextDesktop}>
										<Title>{title}</Title>
										<Typography component="div" dangerouslySetInnerHTML={{ __html: description }} />
									</div>
								</span>
							</Grid>
						))}

						<Grid item xs={12} className={classes.item}>
							<Box display="flex" justifyContent="center" mt={6}>
								<Link to="/kontakt">
									<Button bgColor="#000" size="medium">{ourSaddlefittersButton}</Button>
								</Link>
							</Box>
						</Grid>

					</Grid>
				</Container>
			</Box>
		</Layout >
	)
}

export const query = graphql`
  query querySaddlefitting {
    datoCmsPageSaddlefitting {
      heroTitle
      heroImage {
        gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
      }
      pageSaddlefittingTitle
      pageSaddlefittingDescription
      pageSaddlefittingImage {
        gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED)
      }
      moreThanNumbersTitle
      moreThanNumbersDescription
      correctionTitle
      correctionDescription
      correctionImage {
        gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED)
      }
      consultationTitle
      consultationDescription
      consultationButton
      consultationImage {
        gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED)
      }
      ourSaddlefittersTitle
      ourSaddlefittersButton
      ourSaddlefittersImages {
        id
				title
				description
        image {
          gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED)
        }
      }
    }
  }
`

export default SaddlefittingPage
