import React from 'react'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

import { makeStyles } from '@material-ui/styles'

import Title from '../title'

const useStyles = makeStyles(theme => ({
	teaser: {
		display: 'flex',
		position: 'relative',
		marginLeft: 'auto',
		flexDirection: 'column',
		backgroundColor: theme.palette.secondary.main,
		padding: theme.spacing(5),
		[theme.breakpoints.up('md')]: {
			width: `calc(100% - ${theme.spacing(6)}px)`,
			padding: theme.spacing(8),
			'&:before': {
				top: 0,
				left: `-${theme.spacing(6)}px`,
				width: theme.spacing(6),
				height: theme.spacing(18.5),
				content: '""',
				position: 'absolute',
				backgroundColor: theme.palette.error.main
			}
		},
	},
	centeredTitle: {
		display: 'flex',
		justifyContent: 'center'
	}
}))

const TeaserBox = ({ teaserTitle, teaserDescription, centerTitle }) => {
	const classes = useStyles()

	return (
		<Box mb={10} className={classes.teaser}>
			<Box className={centerTitle && classes.centeredTitle}>
				<Title >{teaserTitle}</Title>
			</Box>
			<Typography
				variant="body2"
				component="div"
				dangerouslySetInnerHTML={{ 
					__html: teaserDescription 
				}} 
			/>
		</Box>
	)
}

export default TeaserBox